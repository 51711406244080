import { useState, useEffect } from 'react'
import {
  RemoteConfig,
  getRemoteConfig,
  fetchAndActivate,
  getBoolean,
  getString,
  isSupported,
  getValue,
} from 'firebase/remote-config'
import { captureException } from '@lojinha/sentry'
import { firebase } from '..'
import { DEFAULT_REMOTE_CONFIG } from './defaults'
import { RemoteConfigKeys } from './remoteConfigKeys'

type RemoteValue<T> = {
  value: T
  loading: boolean
}

const fetchUpdatedValues = async (remoteConfig: RemoteConfig) => {
  try {
    await fetchAndActivate(remoteConfig)
  } catch (e: any) {
    captureException(e)
  }
}

const getAsyncRemoteBoolean = async (
  key: RemoteConfigKeys
): Promise<boolean> => {
  const isRemoteConfigSupported = await isSupported()
  if (!isRemoteConfigSupported) return DEFAULT_REMOTE_CONFIG[key]

  const remoteConfig = getRemoteConfig()
  const source = getValue(remoteConfig, key).getSource()
  if (source !== 'remote') {
    await fetchUpdatedValues(remoteConfig)
  }

  return getBoolean(remoteConfig, key)
}

const useRemoteBoolean = (key: RemoteConfigKeys): RemoteValue<boolean> => {
  const [value, setValue] = useState(DEFAULT_REMOTE_CONFIG[key])
  const [loading, setLoading] = useState(true)

  const getRemoteBoolean = async () => {
    const isRemoteConfigSupported = await isSupported()

    if (!isRemoteConfigSupported) {
      return setLoading(false)
    }

    const remoteConfig = getRemoteConfig()
    await fetchUpdatedValues(remoteConfig)
    setValue(getBoolean(remoteConfig, key))
    setLoading(false)
  }

  useEffect(() => {
    getRemoteBoolean()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { value, loading }
}

const useRemoteString = (key: RemoteConfigKeys): RemoteValue<string> => {
  const [value, setValue] = useState(DEFAULT_REMOTE_CONFIG[key])
  const [loading, setLoading] = useState(true)

  const getRemoteString = async () => {
    const isRemoteConfigSupported = await isSupported()

    if (!isRemoteConfigSupported) {
      return setLoading(false)
    }

    const remoteConfig = getRemoteConfig()
    await fetchUpdatedValues(remoteConfig)
    setValue(getString(remoteConfig, key))
    setLoading(false)
  }

  useEffect(() => {
    getRemoteString()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { value, loading }
}

const RemoteConfigSetup = () => {
  const fetchAndConfigure = async () => {
    const isRemoteConfigSupported = await isSupported()
    if (!isRemoteConfigSupported) return

    const remoteConfig = getRemoteConfig(firebase)
    remoteConfig.settings.minimumFetchIntervalMillis = 0
    await fetchUpdatedValues(remoteConfig)
    remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG
    if (process.env.NODE_ENV === 'production') {
      // 8 hours
      remoteConfig.settings.minimumFetchIntervalMillis = 8 * 60 * 60000
    } else {
      // 10 minutes
      remoteConfig.settings.minimumFetchIntervalMillis = 10 * 60000
    }
  }
  useEffect(() => {
    fetchAndConfigure()
  }, [])

  return null
}

export {
  RemoteConfigSetup,
  useRemoteBoolean,
  useRemoteString,
  getAsyncRemoteBoolean,
  RemoteConfigKeys,
}
